import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

import { Link } from "@/i18n"
import useTranslation from "@/hooks/useTranslation"

import Layout from "@/components/Layout"
import Image from "@/components/Image"
import { Tabs, IconTab } from "@/components/Tabs"

import SaharahContextProvider from "@/contexts/Saharah"

const SaharahLayout = ({ children }) => {
  const queryData = useStaticQuery(graphql`
    query {
      allSaharahYaml {
        nodes {
          name
          avatar {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const { name, avatar } = queryData.allSaharahYaml.nodes[0]

  const t = useTranslation()
  const { pathname } = useLocation()

  let tabName = pathname.split("/saharah-floorwall/")[1]

  if (tabName && tabName.endsWith("/")) {
    tabName = `/${tabName.split("/")[0]}`
  }

  const TAB_OBJ = {
    "/": 0,
    "/floor": 1,
    "/rug": 2,
  }

  const defaultValue = tabName ? TAB_OBJ[tabName] : 0

  return (
    <Layout>
      <LayoutContainer>
        <div className="header">
          <Image className="img" src={avatar.childImageSharp.fluid} />
          <span>{t(name)}</span>
        </div>
        <Tabs value={defaultValue}>
          <IconTab icon={<WallpaperIcon />} label="wallpaper" />
          <IconTab icon={<FloorIcon />} label="floor" />
          <IconTab icon={<RugIcon />} label="rug" />
        </Tabs>
        <SaharahContextProvider>{children}</SaharahContextProvider>
      </LayoutContainer>
    </Layout>
  )
}

export default SaharahLayout

const LayoutContainer = styled.div`
  padding: 0 32px;
  min-height: calc(100vh - 72px - 116px);
  @media (max-width: 450px) {
    padding: 0 20px;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 14px 0 18px;
    .img {
      width: 150px;
      border-radius: 50%;
    }
    span {
      margin: 14px 0 18px;
    }
  }
`

const WallpaperIcon = () => {
  return (
    <Link to="/saharah-floorwall/">
      <svg viewBox="0 0 95 119">
        <g transform="translate(-1.000000, -1.000000)">
          <g transform="translate(48.481514, 59.701761) scale(-1, 1) rotate(-180.000000) translate(-48.481514, -59.701761) translate(0.981514, 0.201761)">
            <path d="M55.8,112.9 C46.7,110 33.3,105.7 26,103.4 C18,100.9 11.6,98.3 9.7,96.7 C-3,86 3.1,65 19.2,63.6 C23.8,63.2 28.6,64.4 53.9,72.4 C70.1,77.4 84.8,82.5 86.6,83.7 C90.5,86.3 94.8,94.5 94.8,99.1 C94.8,106.6 88.4,115.6 81.8,117.4 C76.1,119 73.1,118.5 55.8,112.9 Z M24.9,93.8 C29.1,91.7 32.2,84.6 31.3,79.4 C29.2,66.9 14.6,63.9 9.4,74.9 C7.1,79.7 7.4,85.5 10.1,89.5 C14,95.3 19.2,96.8 24.9,93.8 Z"></path>
            <path d="M49.8,66.3 C34,61.2 23.7,58.4 20.8,58.4 C13.5,58.5 6.2,62.6 1.6,69.4 C-1.52308721e-15,71.8 -0.1,70.1 0.1,36.5 C0.3,5.7 0.5,1 1.9,0.1 C3,-0.6 12.3,2.9 38.9,13.9 C58.4,22 74.9,29.1 75.6,29.7 C76.6976373,30.5537179 76.3023627,74.3391256 75.6,74.300371 C74.9,74.300371 63.3,70.7 49.8,66.3 Z"></path>
          </g>
        </g>
      </svg>
    </Link>
  )
}

const FloorIcon = () => {
  return (
    <Link to="/saharah-floorwall/floor/">
      <svg viewBox="0 0 126 58">
        <g transform="translate(63.259679, 28.950000) scale(-1, 1) rotate(-180.000000) translate(-63.259679, -28.950000) translate(0.959679, -0.000000)">
          <path d="M15.8,56.2 C15.3,55.2 11.4,43 7.1,29.1 C0.9,9.5 -0.6,3.4 0.2,2.1 C1.2,0.5 6.2,0.4 61.6,0.1 C123.6,-0.1 124.6,-0.1 124.6,3.8 C124.6,5.5 109.1,55.2 108.1,56.9 C107.7,57.5 91,57.9 62.1,57.9 C19.8,57.9 16.6,57.8 15.8,56.2 Z M64.4,46.9 L64.7,44.4 L83.9,44.4 C103.1,44.4 103.1,44.4 103.4,42.2 C103.7,40.1 103.4,39.9 98.6,39.9 C93.7,39.9 93.4,39.8 94,37.7 C94.3,36.4 94.6,34.8 94.6,34.2 C94.6,33.3 96.5,32.9 100.6,32.9 C103.9,32.9 106.6,32.7 106.6,32.5 C106.6,32.3 106.9,31.4 107.2,30.5 C107.7,29.1 106.4,28.9 96,28.9 C89.4,28.9 79.7,28.6 74.4,28.2 L64.6,27.6 L64.6,23.8 L64.6,19.9 L87.8,19.7 C109,19.4 111.1,19.2 111.4,17.7 C111.7,16.1 110.9,15.9 105,15.9 L98.3,15.9 L99,11.9 C99.6,8.3 99.4,7.9 97.6,7.9 C95.5,7.9 95.2,8.3 94.2,13.2 L93.6,15.9 L62.7,15.9 L31.8,15.9 L30.9,12.2 C30.4,9.9 29.4,8.3 28.3,8.1 C26.9,7.8 26.6,8.5 26.6,11.8 L26.6,15.9 L19.5,15.9 C13.3,15.9 12.5,16.1 12.8,17.7 C13.1,19.2 15.2,19.4 36.4,19.7 L59.6,19.9 L59.6,23.9 L59.6,27.9 L38.1,27.9 C17.3,27.9 16.6,28 16.6,29.9 C16.6,31.7 17.3,31.9 23,31.9 C30,31.9 30,31.9 31.1,36.7 L31.8,39.9 L25.8,39.9 C20.3,39.9 19.8,40.1 20.3,41.9 C20.8,43.8 21.7,43.9 40.2,43.9 L59.6,43.9 L59.6,46.2 C59.6,47.5 59.9,48.9 60.3,49.3 C61.7,50.7 64.1,49.3 64.4,46.9 Z"></path>
          <path d="M36.1,38.7 C35.8,38 35.5,36.3 35.4,34.9 C35.2,32.9 35.5,32.4 37.4,32.5 C38.6,32.5 51,32.6 65,32.7 L90.3,32.9 L89.7,36.2 L89,39.4 L62.8,39.7 C42.3,39.9 36.4,39.7 36.1,38.7 Z"></path>
        </g>
      </svg>
    </Link>
  )
}

const RugIcon = () => {
  return (
    <Link to="/saharah-floorwall/rug/">
      <svg viewBox="0 0 122 104">
        <g transform="translate(0.000000, -1.000000)">
          <g transform="translate(60.670080, 52.714624) scale(-1, 1) rotate(-180.000000) translate(-60.670080, -52.714624) translate(0.020080, 0.964624)">
            <path d="M12.9,101.6 C4.5,97.8 0,91 0,82 C0,76.3 0.3,75.5 5.5,68 C12.5,57.8 14,52.9 16.8,30.6 C19,13.5 19.4,12.1 22.1,8.7 C23.8,6.7 27.3,3.9 30,2.5 L34.8,0 L77.9,0 L120.9,0 L121.2,2.8 C121.5,5.3 121.3,5.5 117.5,5.5 C114.6,5.5 113.2,6 112.5,7.4 C111.2,9.7 112,10.5 115.6,10.5 C117.6,10.5 118.4,11 118.4,12.3 C118.4,15.7 116.8,16.8 112.6,16.2 C109.6,15.8 108.4,16.1 108,17.2 C106.9,20 107.4,21.1 110.4,22.2 C113.7,23.4 114.3,25.5 111.9,27.5 C110.7,28.5 109.8,28.5 107.7,27.6 C105.4,26.5 104.8,26.6 103.7,28.1 C101.9,30.6 102.1,31.4 104.9,32.7 C106.9,33.6 107.4,34.5 107.2,36.4 C106.9,39 105.7,39.4 101,38.4 C99.8,38.1 98.9,38.8 98.3,40.3 C97.6,42.2 97.9,42.7 99.9,43.5 C101.7,44.2 102.4,45.2 102.4,47.1 C102.4,49.6 102.2,49.7 98.5,49.1 C95.5,48.6 94.5,48.9 94,50.1 C93,52.7 93.3,53.3 96,53.9 C98,54.4 98.5,55.1 98.2,57.3 C97.9,59.7 97.4,60 93.9,60.3 C90.2,60.6 88.5,61.8 88.4,63.9 C88.4,64.3 89.3,64.5 90.5,64.2 C92,63.8 92.8,64.3 93.6,66.1 C95,69.1 94.4,69.7 89.1,70.4 C84.6,71 83.1,73.7 86.9,74.3 C89.8,74.9 90.7,76 89.9,78.4 C89.3,80.5 88.8,80.5 66.3,80.5 L43.4,80.5 L43.4,82.8 C43.4,87.9 41.1,93.1 37,97 C30.4,103.4 20.8,105.2 12.9,101.6 Z M86.4,40 C93.5,23.5 99.2,9.9 99.1,9.9 C99,9.8 88.3,9.5 75.4,9.1 C54.7,8.6 52.3,8.7 55.7,9.9 C62.8,12.5 65.4,16.5 65.4,24.7 C65.3,31.4 62.4,38.6 55.2,49.6 C50.7,56.5 45.4,67.6 45.4,70.1 C45.4,70.3 51.8,70.4 59.5,70.3 L73.6,70 L86.4,40 Z M49.1,37.8 C55.8,34.7 58.8,24.9 54.4,20.5 C52.1,18.2 49.5,18 44.7,19.7 C41.6,20.8 41.2,20.7 38.7,18.3 C35.4,15 36.1,13 41.6,10.4 L45.9,8.4 L41.4,9.1 C35.9,9.9 32.7,11.5 29.4,14.9 C25.8,18.8 25.3,28.8 28.5,32.9 C33.6,39.3 41.8,41.3 49.1,37.8 Z"></path>
          </g>
        </g>
      </svg>
    </Link>
  )
}
