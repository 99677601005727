import React, { createContext,useState, useReducer, useEffect } from 'react';
import { SaharahReducer } from './reducer';

export const SaharahContext = createContext();

const SaharahContextProvider = ({children}) => {
  const [hasInit, setHasInit] = useState(false);
  const [saharah, dispatch] = useReducer(SaharahReducer, []);
  useEffect(()=>{
    const localData = localStorage.getItem('saharah');
    const localSaharah = localData ? JSON.parse(localData) : [];
    if(Array.isArray(localSaharah) && localSaharah.length){
      dispatch({
        type: 'UPDATE_LOCAL_DATA',
        value: localSaharah
      })
    }
    setHasInit(true);
  },[])
  useEffect(() => {
    if(hasInit){
      localStorage.setItem('saharah', JSON.stringify(saharah));
    }
  }, [saharah]);
  return (
    <SaharahContext.Provider value={{ saharah, dispatch }}>
      {children}
    </SaharahContext.Provider>
  );
}
 
export default SaharahContextProvider;