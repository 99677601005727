import React, { useState, useContext } from "react"
import styled from "styled-components"

import useFormatLocale from "@/hooks/useFormatLocale"
import Image from "@/components/Image"
import floorwallBg from "../../icons/floorwall.svg"

import { SaharahContext } from "@/contexts/Saharah"

const styledCard = {
  // card main color
  bg: "#f9b9ba",
  // card border color
  border: "#f4f0e2",
  // tick highlight color
  icon: "#603f3e",
  // tick border color
  line: "#f9b9ba",
  // checkbox border color
  fill: "#f8aeac",
}

const FloorWallCard = ({ floorwall }) => {
  const lng = useFormatLocale()
  const { name, icon, isCollected } = floorwall
  const { dispatch } = useContext(SaharahContext)

  const handleCheckbox = () => {
    if (isCollected) {
      dispatch({ type: "REMOVE_MARK_COLLECTED", id: floorwall.id })
    } else {
      dispatch({ type: "ADD_MARK_COLLECTED", id: floorwall.id })
    }
  }
  return (
    <StyledFloorWallCard bg={floorwallBg}>
      <div
        style={{
          borderRadius: `2px`,
          boxShadow: `0 0 0 6px ${styledCard.border}`,
          backgroundColor: `${styledCard.border}`,
        }}
      >
        <div className="floorwall-bg">
          <div className="floorwall-icon">
            <Image src={icon.childImageSharp.fluid} />
          </div>
          {isCollected && (
            <div className="floorwall-storage">
              <StorageMarkIcon color={styledCard} />
            </div>
          )}
          <div
            className="floorwall-checkbox"
            onClick={handleCheckbox}
            style={{
              boxShadow: `0 0 0 3px ${styledCard.fill}`,
            }}
          >
            <CheckMarkIcon isMark={isCollected} color={styledCard} />
          </div>
        </div>
      </div>
      <span className="floorwall-title">{name[lng]}</span>
    </StyledFloorWallCard>
  )
}

export default FloorWallCard

const StyledFloorWallCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  @media (max-width: 450px) {
    width: calc(50vw - 20px);
  }
  .floorwall-bg {
    width: 124px;
    height: 184px;
    background-size: cover;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 4px;
    background-image: url(${(props) => props.bg});
    .floorwall-icon {
      cursor: pointer;
      width: 72%;
    }
  }
  .floorwall-title {
    margin-top: 18px;
    max-width: 124px;
    text-align: center;
  }
  .floorwall-storage {
    position: absolute;
    bottom: 6px;
    left: 3px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .floorwall-checkbox {
    position: absolute;
    right: 6px;
    bottom: 6px;
    width: 17px;
    height: 17px;
    border-radius: 2px;
    cursor: pointer;
    background-color: transparent;
    svg {
      position: absolute;
      left: -4px;
      top: -2px;
      width: 26px;
      path {
        transition: 0.3s fill;
      }
    }
  }
`

const StorageMarkIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 45 45">
      <g transform="translate(-1.000000, -1.000000)">
        <g transform="translate(23.880366, 23.027172) scale(-1, 1) rotate(-180.000000) translate(-23.880366, -23.027172) translate(0.880366, 0.527172)">
          <path
            fill={color.icon}
            d="M34.4,40 C39.5,37.7 43.9,35.3 44.3,34.7 C44.7,34.2 45.1,28.5 45.1,22.2 C45.1,15.9 44.7,10.2 44.3,9.7 C43.1,7.9 25.4,0.2 22.6,0.2 C19.8,0.2 2.1,7.9 0.9,9.7 C-0.1,11 -0.1,33.4 0.9,34.7 C2.2,36.5 19.5,44.1 22.4,44.2 C24,44.2 29.4,42.3 34.4,40 Z"
          ></path>
          <path
            fill={color.fill}
            d="M17.6,39.2 C15.4,38.2 11.4,36.4 8.6,35.3 C2.9,32.8 2.7,33 14.7,27.7 L22.7,24.1 L31.9,28.3 C37,30.6 40.8,32.8 40.4,33.2 C39.4,34.2 23.2,41.2 22.3,41.1 C21.9,41.1 19.8,40.2 17.6,39.2 Z"
          ></path>
          <path
            fill={color.fill}
            d="M6.1,23.8 C6.1,22.6 10.8,20.2 13.2,20.2 C15.5,20.2 13.7,23.1 11,24.1 C6.8,25.5 6.1,25.5 6.1,23.8 Z"
          ></path>
        </g>
      </g>
    </svg>
  )
}

const CheckMarkIcon = ({ isMark, color }) => {
  const mainColor = isMark ? color.icon : color.fill
  const lineColor = isMark ? color.fill : color.bg
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 59 47">
      <g transform="translate(0.000000, -1.000000)">
        <g transform="translate(29.500000, 24.180919) scale(-1, 1) rotate(-180.000000) translate(-29.500000, -24.180919) translate(0.000000, 0.680919)">
          <path
            fill={lineColor}
            d="M47,46.3 C46.2,46 40.4,41.4 34.2,36.2 L22.9,26.7 L19.7,29.8 C16.1,33.3 10.8,34.1 7.7,31.8 C4.5,29.3 0,22.7 0,20.4 C0,15.8 16.9,0 21.8,0 C24.7,0 30.1,5.2 48.8,25.8 C58.7,36.7 59.8,39.6 56.3,43.9 C54.4,46.1 49.6,47.4 47,46.3 Z"
          ></path>
          <path
            fill={mainColor}
            d="M52,40.5 C52.6,39.7 52.8,38.5 52.4,37.8 C50.7,35 22.7,5 21.8,5 C20.3,5 5,19 5,20.3 C5,22.1 11.2,28 13.1,28 C14.1,28 16.3,26.2 18,24 C19.7,21.8 21.6,20 22.1,20 C22.7,20 28.9,24.9 36.1,31 C43.2,37.1 49.5,42 49.9,42 C50.4,42 51.3,41.3 52,40.5 Z"
          ></path>
        </g>
      </g>
    </svg>
  )
}

